<template>
  <div class="pa-9">
    <h1>
      Cấu hình trang Điều khoản sử dụng
    </h1>
    <ValidationObserver ref="add_question">
      <div>
        <h4>Tiêu đề</h4>
        <ValidationProvider
            :name="'title'"
            rules="required"
            v-slot="{ errors }"
        >
          <v-file-input
              class="pl-1"
              prepend-icon="mdi-camera"
              v-model="image"
              label="Tải ảnh"
              @change="onChangeFileUpload"
              @click:clear="clearImage"
          ></v-file-input>
          <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
        </ValidationProvider>

      </div>
    </ValidationObserver>
    <v-row class="d-flex align-center justify-center my-9">
      <v-col cols="4">
        <v-btn color="primary" @click="saveConfig">{{ save }}</v-btn>
      </v-col>
    </v-row>
    <v-data-table
        v-if="setting && setting.length > 0"
        :headers="headers"
        :items="setting"
        class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:item.src="{ item }">
        <v-img :src="item.src" width="200px"></v-img>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {UPLOAD_IMAGE} from "@/store/media.module";
import {GET_CONFIG, SAVE_CONF, SET_CONFIG_ERROR} from "@/store/setting.module";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";

export default {
  computed: {
    ...mapGetters({
      setting: "setting"
    })
  },
  data() {
    return {
      image: {},
      save: 'Lưu',
      headers: [
        {text: "STT", value: "id"},
        {text: "Ảnh", value: "src"},
        {text: "Xóa", value: "actions", sortable: false}
      ],
    }
  },

  mounted() {
    let key = {
      'key': 'slide_book'
    }
    this.$store.dispatch(GET_CONFIG, key);
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Cấu hình điều khoản sử dụng"}]);
    this.$store.commit(SET_CONFIG_ERROR, [])
  },
  methods: {
    onChangeFileUpload() {
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store.dispatch(UPLOAD_IMAGE, formData).then(data => {
        let object = {};
        if (data.data.status) {
          if (this.setting.length > 0) {
             object = {
              id: (this.getMaxKey() + 1),
              src: data.data.data
            }
            this.setting.push(object)
          } else {
            object = {
              id:  1,
              src: data.data.data
            }
            this.setting.push(object)
          }
        }
      });
    },
    clearImage() {
      this.show = false;
    },
    async deleteItem(item) {
      let  data_delete = []
      if (this.setting.length == 1) {
        data_delete = []
      } else {
        data_delete = this.setting.filter(e => e.id !== item.id)
      }
      let data = {
        key: "slide_book",
        value: data_delete
      }
      if (confirm("Are you sure you want to delete this item?")) {
        await this.$store.dispatch(SAVE_CONF, data).then(data => {
          if (data.data.status) {
            this.$store.dispatch(GET_CONFIG, {
              'key': 'slide_book'
            });
          }
        })
      }
    },
    async saveConfig() {
      let payload = {
        key: 'slide_book',
        value: this.setting
      }
      console.log(payload, 'payload')
      this.$store.dispatch(SAVE_CONF, payload).then(data => {
        if (data.status) {
          this.$toasted.success('Lưu thành công', {
            position: "top-right",
            theme: "toasted-primary",
            duration: 1000
          });
        }
      });
    },
    getMaxKey() {
      let max = 1
      if (this.setting.length > 0) {
        this.setting.map((item) => {
          if (item.id >= max) {
            max = item.id
          }
        })
      }
      return max
    }
  }
}
</script>